<template>
    <b-container class="mb-4">
        <b-card class="text-center">
            <h2>
                <font-awesome-icon
                    icon="sitemap"
                    class="mr-2"
                ></font-awesome-icon>
                Committees
            </h2>
            <div class="mt-3">
                <router-link
                    class="sc-btn pill"
                    :to="{ name: 'admin_form', params: { formKey: 'add-committee' } }"
                >
                    <font-awesome-icon
                        class="mr-1"
                        icon="plus-circle"
                    ></font-awesome-icon>
                    Add Committee
                </router-link>
            </div>
        </b-card>
        <base-list
            class="mt-2"
            listUrl="/api/committees/admin/fetch-committees"
            deleteUrl="/api/committees/admin/remove-committee"
        >
            <template #content="{ item: record }">
                <h6>{{ record.committee_name }}</h6>
                <b-badge
                    v-if="record.membership_period"
                    variant="primary"
                >
                    <font-awesome-icon
                        icon="info-circle"
                        class="mr-1"
                    ></font-awesome-icon>
                    Memberships expire after {{ record.membership_period }} days
                </b-badge>
                <div>{{ record.help_text }}</div>
            </template>
            <template #actions="{ item: record }">
                <b-button
                    variant="link"
                    @click="toggleVisibility(record)"
                >
                    <font-awesome-icon
                        :icon="(record.visible) ? 'eye' : 'eye-slash'"
                    ></font-awesome-icon>
                </b-button>
                <router-link
                    is="b-button"
                    class="ml-2"
                    variant="link"
                    :to="{ name: 'view_committee_members', params: { id: record.id }}"
                >
                    <font-awesome-icon
                        icon="users"
                    ></font-awesome-icon>
                </router-link>
                <router-link
                    is="b-button"
                    class="ml-2"
                    variant="link"
                    :to="{ name: 'admin_form', params: { formKey: 'edit-committee' }, query: { id: record.id } }"
                >
                    <font-awesome-icon
                        icon="pencil-alt"
                    ></font-awesome-icon>
                </router-link>
            </template>
        </base-list>
    </b-container>
</template>
<script>
import BaseList from '@/components/ui/BaseList.vue';

export default {
  components: { BaseList },
    data() {
        return {
            src          : `/api/committees/admin/fetch-committees`,
            loading      : false,
            records      : []
        };
    },
    mounted() {
        this.loading = true;
        this.loadRecords();
    },
    methods: {
        loadRecords() {
            this.loading = true;
            this.$api.get(
                this.src
            ).then(({ data }) => {
                this.records = data.records;
                this.loading = false;
            });
        },
        confirmDelete(committee) {
            this.$bvModal.msgBoxConfirm("Please confirm that you want to delete the committee '" + committee.committee_name + ".'", {
                title           : "Please Confirm",
                size            : "sm",
                buttonSize      : "sm",
                okVariant       : "danger",
                okTitle         : "Delete",
                cancelTitle     : "Cancel",
                footerClass     : "p-2",
                hideHeaderClose : false,
                centered        : true
            })
            .then(deleteConfirmed => {
                if (deleteConfirmed) {
                    this.deleteCommittee(committee);
                }
            })
            .catch(err => {
                console.log("Error:", err);
            });
        },
        deleteCommittee(committee) {
            this.$api.post(
                "/api/committees/admin/remove-committee",
                {
                    id: committee.id
                }
            ).then(({ data }) => {
                if (data.success)
                    committee.removed = true;
                this.$bvToast.toast(data.message, {
                    title           : (data.success) ? "Success!" : "Error!",
                    variant         : (data.success) ? "success"  : "danger",
                    autoHideDelay   : 5000,
                    appendToast     : true
                });
            });
        },
        toggleVisibility(committee) {
            this.$api.post(
                "/api/committees/admin/toggle-visibility",
                {
                    id: committee.id
                }
            ).then(({ data }) => {
                if (data.success)
                    committee.visible = data.visible;
                this.$bvToast.toast(data.message, {
                    title           : (data.success) ? "Success!" : "Error!",
                    variant         : (data.success) ? "success"  : "danger",
                    autoHideDelay   : 5000,
                    appendToast     : true
                });
            });
        }
    }
}
</script>